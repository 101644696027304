<template>
  <div class="d-flex-column align-center main">
    <div class="main full-width input-underline">
      <el-card v-if="aqsForm">
        <div class="mw-400 main" :key="componentKey">
          <h3 class="mt-0">Additional Questions</h3>
          <el-form label-position="top" class="">
            <el-row :gutter="60">
              <el-col :span="24" class="mt-1" v-for="(value, key) in aqsForm" :key="`aqs_${aqsForm[key]._id}`">
                  <SuperInput
                    :field="aqsForm[key]"
                    v-model="aqsForm[key].value"
                    :errors.sync="aqsForm[key].errors"
                    :type="aqsForm[key].type"
                    :titleHtmlify="true"
                  />
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
    </div>
     <div class="d-flex-column main mw-400">
        <div class="align-text-center">
          <el-button type="primary" class="full-width" @click="next">Next</el-button>
        </div>
        <div class="align-text-center">
          <el-button class="full-width button-invisible" @click="back">Back</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  errorOrPass, flatten, makeForm,
} from '@/utils/forms';
import SuperInput from '@/components/form/fields/SuperInput.vue';

export default {
  name: 'PlayerAdditionalQuestions',
  components: { SuperInput },
  mounted() {
    this.componentKey += 1;

    // custom additional questions reform
    const { price: { pricing } } = this.current;

    if (pricing && pricing.competition) {
      const { additionalQuestions } = pricing.competition;
      _.forEach(additionalQuestions, ({
        _id, fieldName, type, question, required, meta: { options },
      }) => {
        this.$set(this.aqsForm, _.camelCase(fieldName)+ "_" +_.camelCase(_id), {
          defaultType: type,
          type: type === 3 ? 'number' : 'text',
          // eslint-disable-next-line no-nested-ternary
          component: [1, 3].includes(type) ? 'PlainInput' : (type === 2 ? 'SelectSP' : 'CheckboxSP'),
          options,
          label: question,
          required,
          displayed: () => true,
          value: [1, 2, 3].includes(type) ? '' : [],
          errors: [],
          _id: _.camelCase(fieldName)+ "_" +_.camelCase(_id),
        });
      });
      this.aqsForm = makeForm(this.aqsForm);
    }
  },
  data() {
    return {
      componentKey: 0,
      aqsForm: {},
    };
  },
  computed: {
    ...mapGetters('registrationTeams', ['current']),
    nationalId() {
      return _.get(this.current, 'entity.orgtree.national._id', 31);
    },
    registerToAssoc() {
      return _.get(this.current, 'entity.entityType') === 'association';
    },
  },
  methods: {
    ...mapActions('views', ['triggerEvent']),
    ...mapActions('registrationTeams', ['updateCurrent']),
    back() {
      this.$router.go(-1);
    },
    next() {
      this.triggerEvent();

      // validate additional questions form
      if (this.aqsForm && !errorOrPass(this.aqsForm, [], this)) return;

      // values of additional questions
      const answers = this.aqsForm && flatten(this.aqsForm);

      // Update any additional form fields
      this.updateCurrent({ answers });

      if (this.current.price.pricing.competition.saleableItems
            && this.current.price.pricing.competition.saleableItems.length > 0) {
        this.$router.push({ name: 'teamaddon' });
      } else {
        this.$router.push({ name: 'teampayment' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-input {
  .el-input__suffix {
    z-index: 1;
  }
}

::v-deep .form-control label{
    bottom: 12px;
}

.information-link {
  font-size: 0.875rem;
}

.modal-content {
  margin-bottom: 4rem;
  @media (min-width: $sm) {
    margin-bottom: 0;
  }
  .bold {
    font-family: $fontBold;
  }
}
</style>
